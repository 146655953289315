import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { ImCross } from "react-icons/im"
import { useSearchParams } from "react-router-dom"
import Swal from "sweetalert2"
import Loadingwrapper from "../../component/LoadingWrapper"
import { HeadersRanking, AllBrandsOnLeaderboard } from "../../constants"
import GroupTrend from "./GroupTrend"

const V2Leaderboard = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const [page, setPage] = useState(1)
  const [groupTrends, setGroupTrends] = useState([])
  const [showGeography, setShowGeography] = useState(false)
  const [showSource, setShowSource] = useState(false)
  const [showBrand, setShowBrands] = useState(false)
  const [showTimeframe, setShowTimeframe] = useState(false)
  const publishDate = useRef(new Date().toDateString())
  const [loading, setLoading] = useState(false)
  const [brandCounts, setBrandCounts] = useState([])
  const [totalRetailerImages, setTotalRetailerImages] = useState(0)

  useEffect(() => {
    axios.get(`/social-trends-v2-api/leaderboard-brand-images-count?gender=${searchParams.get('gender') || 'Female'}&category=${searchParams.get('category').split('&').join('%26')}${searchParams.get('tag') ? `&tag=${encodeURIComponent(searchParams.get('tag'))}` : ''}${searchParams.get('value') ? `&value=${encodeURIComponent(searchParams.get('value'))}` : ''}}`).then(resp => {
      setBrandCounts(resp.data.brandCounts)
      let totalCounts = 0;
      resp.data.brandCounts.forEach(t => {
        totalCounts = totalCounts + t.count;
      })
      setTotalRetailerImages(totalCounts)
    })
  }, [searchParams.get('gender'), searchParams.get('category')])

  useEffect(() => {
    setSearchParams(prev => {
      prev.set('time', prev.has('time') ? prev.get('time') : '7 days')
      prev.set('geography', prev.has('geography') ? prev.get('geography') : 'All')
      prev.set('source', prev.has('source') ? prev.get('source') : '')
      return prev
    })
  }, [])

  const getTrends = async (signal) => {
    setLoading(true)
    setGroupTrends([])
    const resp = await axios.get(`/social-trends-v2-api/leaderboard-trends?gender=${searchParams.get('gender') || 'Female'}&category=${searchParams.get('category').split('&').join('%26')}&geography=${searchParams.get('geography') || 'All'}&source=${searchParams.get('source') || ''}&time=${searchParams.get('time') || '30 days'}&page=${page || 1}&limit=${searchParams.get('limit') || 50}${searchParams.get('tag') ? `&tag=${searchParams.get('tag').split('&').join('%26')}` : ''}&value=${encodeURIComponent(searchParams.get('value'))}&sort=${searchParams.get('sort')}${searchParams.get('brand') ? `&brand=${encodeURIComponent(searchParams.get('brand'))}` : ''}`, { signal })
    setLoading(false)
    setGroupTrends(resp.data.trendGroups.sort((a, b) => {
      if (HeadersRanking[searchParams.get('category')]) {
        return HeadersRanking[searchParams.get('category')][a.tag] - HeadersRanking[searchParams.get('category')][b.tag]
      }
      return 1
    }))


  }
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    try {
      getTrends()
    } catch (e) {
      console.error(e)
    }
  }, [searchParams])

  return <div>
    <div className="py-2 bg-black sticky top-10 z-20">
      <p className="text-white text-center text-2xl">TRENDS IN {searchParams.get('gender') == 'Female' ? 'WOMEN' : 'MALE'} {'>'} {searchParams.get('category')} {searchParams.get('tag') ? <>{'>'} <span className="text-red-400"> <span className="font-semibold">{searchParams.get('value') != '#N/A' ? searchParams.get('value') : 'No Specific'}</span>  ({searchParams.get('tag')})<button onClick={() => {
        setSearchParams(prev => {
          prev.delete('tag')
          prev.delete('value')
          return prev
        })
      }}><ImCross size={16} /></button>
      </span>
      </> : null}</p>
    </div>
    <div className="py-2 flex bg-gray-600 text-white justify-between">
      <div className="flex">
        {false &&
          <div className="mx-6 w-52 relative " onMouseEnter={() => { setShowSource(true) }} onMouseLeave={() => setShowSource(false)}>
            <div className="w-full text-nowrap overflow-hidden">Channel : {!searchParams.get('source') || searchParams.get('source') == '' ? 'All' : `${searchParams.get('source').split(',')[0]}${searchParams.get('source').split(',').length > 1 ? `(+ ${searchParams.get('source').split(',').length - 1})` : ''} `}          </div>
            {showSource && <div className="absolute w-full left-0 py-2 bg-gray-600 text-[10px] text-wrap">

              {['All', 'Mega Influencers', 'Micro Influencers', 'Celebrities', 'Pages/Magazines', 'Stores/Brands']
                .map(t => <button
                  key={t}
                  className={`mx-2 ${(searchParams.get('source') || '').split(',').includes(t) || (searchParams.get('source') == '' && t == 'All') ? 'font-semibold underline' : ''} w-full text-left px-4 py-1`}
                  onClick={() => {
                    setSearchParams(prev => {
                      let newVal = prev.get('source');
                      if (t == 'All') {
                        newVal = ''
                      } else {
                        if (newVal.includes(t)) {
                          newVal = newVal.split(',').filter((item) => item != t).join(',')

                        } else {
                          newVal = newVal == '' ? t : newVal + ',' + t
                        }
                      }
                      if (newVal.split(',').length == 5) {
                        newVal = ''
                      }
                      prev.set('source', newVal);
                      return prev
                    })
                  }}
                >{t}</button>)}
            </div>}
          </div>
        }
        <div className="mx-6 relative" onMouseEnter={() => { setShowGeography(true) }} onMouseLeave={() => setShowGeography(false)}>

          <div className="w-full text-nowrap overflow-hidden">Geography: {searchParams.get('geography')} </div>
          {showGeography && <div className="absolute w-full left-0 py-2 bg-gray-600 text-[10px] text-wrap">

            {['All', 'Western', 'Asian', 'Indian'].map(t => <button
              key={t}
              className={`mx-2 ${(searchParams.get('geography') == t) ? 'font-semibold underline' : ''} w-full text-left px-4 py-1`}
              onClick={() => {
                setSearchParams(prev => {
                  prev.set('geography', t);
                  return prev
                })
              }}
            >{t}</button>)}
          </div>}

        </div>

        <div className="mx-6 relative" onMouseEnter={() => { setShowTimeframe(true) }} onMouseLeave={() => setShowTimeframe(false)}>

          <div className="w-full text-nowrap overflow-hidden">Trending in : Past {searchParams.get('time')} </div>

          {showTimeframe && <div className="absolute w-full left-0 py-2 bg-gray-600 text-[10px] text-wrap text-left">
            {['7 days', '15 days', '30 days', '60 days', '90 days', '180 days']
              .map(t => <button
                key={t}
                className='mx-2 disabled:font-semibold disabled:underline w-full'
                disabled={t == searchParams.get('time')}
                onClick={() => {
                  setSearchParams(prev => {
                    prev.set('time', t);
                    return prev
                  })
                }}
              >{t}</button>)}
          </div>}
        </div>
      </div>

      <div className="mx-6 relative" onMouseEnter={() => { setShowBrands(true) }} onMouseLeave={() => setShowBrands(false)}>

        <div className="w-40 shrink-0 overflow-hidden">Brand: {searchParams.get('brand') || 'All'} </div>
        {showBrand && <div className="absolute w-full left-0 py-2 bg-gray-600 text-[10px] text-wrap max-h-96 overflow-y-auto">
          <button
            className={`mx-2 ${(!searchParams.get('brand')) ? 'font-semibold underline' : ''} w-full text-left px-4 py-1`}
            onClick={() => {
              setSearchParams(prev => {
                prev.delete('brand');
                return prev
              })
            }}
          >All({totalRetailerImages})</button>

          {
            brandCounts.map(t => <button
              key={t.brand}
              className={`mx-2 ${(searchParams.get('brand') == t.brand) ? 'font-semibold underline' : ''} w-full text-left px-4 py-1`}
              onClick={() => {
                setSearchParams(prev => {
                  prev.set('brand', t.brand);
                  return prev
                })
              }
              }
            >{t.brand}({t.count})</button>)
          }
        </div>}

      </div>
      <div className="px-10 italic text-[12px] font-normal flex">
        <button onClick={() => {
          window.navigator.clipboard.writeText(window.location)
          Swal.fire({ title: "URL copied. ", timer: 1400, icon: 'success' })
        }}
          className='relative -top-0.5 px-2 mr-1 hover:bg-slate-900 py-1 rounded '
        >Share Leaderboard</button>
        <div>By FastFashionTrend.com on {publishDate.current}</div>

      </div>
    </div>
    <Loadingwrapper loading={loading} className="pt-40">
      <div className="w-screen flex flex-wrap ">
        {groupTrends.map((gr, index) => <GroupTrend key={gr.tag} searchParams={searchParams} trendsData={gr} index={index} setSearchParams={setSearchParams} />)}

      </div>
    </Loadingwrapper>

  </div >
}

export default V2Leaderboard
