import axios from "axios"
import { useState, useEffect } from "react"
import ModalImage from "react-modal-image";
import Loadingwrapper from "../../component/LoadingWrapper"
import ImageTagsDetails from "../SocialTrendsTags/ImageTagsDetails";
import Collection from "./Collection";

const TrendImages = ({ trendType, searchParams, tag, value }) => {
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(1)
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([])
  const [brandCounts, setBrandCounts] = useState([])
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [allCollectionNames, setAllCollectionNames] = useState([])
  const [collectionSets, setCollectionSets] = useState({})
  const limit = 100

  const getCollectionNames = async () => {
    const resp = await axios.get(`/api/saved-images/collections`)
    setAllCollectionNames(resp.data.collections)
    let collectionSetsTemp = {}
    resp.data.collections.forEach(coll => {
      collectionSetsTemp[coll._id] = new Set(coll[trendType === 'social' ? 'savedImages' : 'savedProducts']);
    })
    setCollectionSets(collectionSetsTemp)
  }



  const addImageToCollection = async (image, collectionId) => {
    const body = {
      imageId: image._id,
      collectionId
    }
    if (collectionSets[collectionId] && collectionSets[collectionId].has(image._id)) {
      body.delete = true
    }
    await axios.post(`/api/saved-images/image/${trendType}`, body)
    getCollectionNames()
  }

  useEffect(() => {
    if (searchParams.get('brand')) {
      setSelectedBrand(searchParams.get('brand'))
    }
  }, [])

  const getImages = async () => {
    setLoading(true)
    setImages([])
    const resp = await axios.get(`/social-trends-v2-api/leaderboard-trend-images/${trendType}?gender=${searchParams.get('gender') || 'Female'}&category=${searchParams.get('category').split('&').join('%26')}&geography=${searchParams.get('geography') || 'All'}&source=${searchParams.get('source') || ''}&time=${searchParams.get('time') || '30 days'}&page=${page || 1}&limit=100${searchParams.get('tag') ? `&tag=${encodeURIComponent(searchParams.get('tag'))}` : ''}${searchParams.get('value') ? `&value=${encodeURIComponent(searchParams.get('value'))}` : ''}&trendTag=${encodeURIComponent(tag)}&trendValue=${encodeURIComponent(value)}${trendType === 'retailer' && selectedBrand != null ? `&brand=${encodeURIComponent(selectedBrand)}` : ''}`)
    setLoading(false)
    setCount(resp.data.count)
    setImages(resp.data.images)
    if (trendType === 'retailer') {
      setBrandCounts(resp.data.brandCounts)
    }
  }
  useEffect(() => {
    getImages()
  }, [searchParams, tag, value, page, selectedBrand])

  useEffect(() => {
    getCollectionNames()
  }, [])

  return <div className="w-[80vw] h-[80vw] bg-white">
    <h3 className="text-center font-semibold text-[20px]">{value != '#N/A' ? value : 'No Specific'} {tag} Trend Images</h3>
    <div className="flex justify-between">
      <div>{trendType === 'retailer' ? <div className="flex border-b border-slate-200 overflow-x-auto h-13 py-2 hover:h-auto w-[60vw] hover:flex-wrap transition-[height] transition-all duration-1000">
        {brandCounts.map(brand => <button
          key={brand._id}
          onClick={() => {
            setSelectedBrand(prev => {
              return brand._id != prev ? brand._id : null
            })
          }}
          className={` text-nowrap border rounded-2xl px-2 text-xs font-medium hover:bg-slate-100 ${selectedBrand == brand._id ? "bg-slate-300" : "bg-white"} py-1 ml-1`}
        >{brand._id}({brand.count})</button>)}
      </div> : null}</div>
      <div>
        <button className="primary-btn" onClick={() => { setPage(prev => prev - 1) }} disabled={page <= 1}>prev</button> {page}/{Math.ceil(count / limit)}
        <button className="primary-btn" onClick={() => { setPage(prev => prev + 1) }} disabled={page >= Math.ceil(count / limit)}>next</button>
      </div>
    </div>
    <Loadingwrapper loading={loading}>
      <div className="w-full flex flex-wrap my-2">
        {images.map(image => <div className="w-1/4 p-2 border border-slate-300">
          <div className="flex justify-center items-center relative group">
            <div className="relative group">
              <Collection image={image} collections={allCollectionNames} fetchCollections={getCollectionNames} addImageToCollection={addImageToCollection} collectionSets={collectionSets} />
              <ModalImage
                small={image[trendType == 'social' ? 'imageUrl' : 'image_url']}
                large={image[trendType == 'social' ? 'imageUrl' : 'image_url']}
                className='h-60'
              />
            </div>
          </div>
          {trendType == 'social' ? <div className="px-5 text-xs">
            <a href={`${image.postUrl}`} className='text-blue-600 font-medium' target='_blank'>{image.username}</a>
            <p>{new Date(image.timeStamp).toDateString()}</p>
            <ImageTagsDetails mediaId={image.mediaId} />
          </div> : <div className="px-3">
            <a href={`${image.product_url}`} className='text-blue-600 font-medium' target='_blank'>{image.retailer}</a>
            <p>Brand : {image.brand}</p>
            <p className="w-full text-nowrap overflow-hidden text-[11px]">{image.title}</p>
            <ImageTagsDetails mediaId={image.pk} isretailer={true} />
          </div>}

        </div>)}
      </div>
    </Loadingwrapper>


  </div>
}

export default TrendImages
