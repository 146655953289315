import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import NewQuery from './component/Home';
import ImgUploded from './component/ImgUploded';
import TopBar from './component/TopBar';
import NotFound from './component/NotFound';
import Login from './component/Login'
import TrendDisplay from './pages/trendDisplayPage';
import RaWImage from './pages/rawImage';
import TrendGraph from './pages/trendGraph';
import WishList from './pages/wishlist';
import DeletedTrends from './pages/deletedTrend';
import DesignTrends from './pages/designTrends';
import ProductsDesignTrends from './pages/ProductsDesignTrends'
import Curator from './pages/Curator';
import ImageTagsTable from './pages/ImageTagsTable';
import RetailerTags from './pages/CuratorPages/RetailerTags';
import CuratorData from './pages/CuratorData';
import ProductsSearch from './pages/ProductsSearch'
import CommentsGraph from './pages/CommentsGraph';
import Leaderboard from './pages/LeaderBoard';
import ProductsAnalysis from './pages/ProductsAnalysis';
import TagsAnalysis from './pages/TagsPage';
import Trend from './pages/Trend';
import DesignTrendsFarFetch from './pages/DesignTrendsFarFetch';
import DesignerFeatureTrends from './pages/DesignerFeatureTrends';
import SocialMetaTrends from './pages/SocialMetaTrends';
import SlideWebsite from './pages/SlideWebsiteRedirection';
import MediaIdDisplay from './pages/CuratorImagePage';
import MetaCuratorTagsPopup from './pages/MetaCuratorTagsPopup';
import MetaRetailerProductsData from './pages/MetaRetailerProductsData';
import SocialTrendsTags from './pages/SocialTrendsTags';
import V2CategoriesHomePage from './pages/V2CategoriesHomePage';
import AdminPasscodes from './pages/Admin/AdminPasscodes';
import CheckLoggedIn from './component/CheckLoggedIn';
import SavedImages from './pages/SavedImages';
import SavedImagesCollections from './pages/SavedImagesCollections';
import SavedImagesCollection from './pages/SavedImagesCollection';
import Moodboard from './pages/Moodboard';
import V2Leaderboard from './pages/V2Leaderboard';
import DesignTrendLifecycle from './pages/DesignTrendLifeCycles';
import CombinationTrendPageWrapper from './pages/CombinationTrendPage';
function App() {
  return (
    <BrowserRouter>
      <TopBar />
      <Routes>
        <Route path='/' element={<CheckLoggedIn><V2CategoriesHomePage /></CheckLoggedIn>} />
        <Route path='/leaderboard' element={<Leaderboard />} />
        <Route path='/v2-leaderboard' element={<V2Leaderboard />} />
        <Route path='/v2-combination-trend/:type/:id' element={<CombinationTrendPageWrapper />} />
        <Route path='/design-trends-lifecycle' element={<CheckLoggedIn><DesignTrendLifecycle /></CheckLoggedIn>} />
        <Route path='/saved-images' element={<CheckLoggedIn><SavedImagesCollections /></CheckLoggedIn>} />
        <Route path='/saved-images/:Id' element={<SavedImagesCollection />} />
        <Route path='/social-trends' element={<TrendDisplay />} />
        <Route path='/women-social-trends' element={<CheckLoggedIn><SocialTrendsTags gender={'Female'} /></CheckLoggedIn>} />
        <Route path='/men-social-trends' element={<CheckLoggedIn><SocialTrendsTags gender={'Male'} /></CheckLoggedIn>} />
        <Route path='/website' element={<SlideWebsite />} />
        <Route path='/design' element={<DesignTrends />} />
        <Route path='/trends-leaderboard' element={<Leaderboard />} />
        <Route path='/competitor-analysis' element={<ProductsAnalysis />} />
        <Route path='/social-analysis' element={<TagsAnalysis />} />
        <Route path='/t/:trendId' element={<Trend />} />
        <Route path='/curator-meta-tags/:mediaId' element={<MetaCuratorTagsPopup />} />
        <Route path='/image-metadata/:mediaId' element={<MetaCuratorTagsPopup />} />
        <Route path='/product-metadata/:mediaId' element={<MetaRetailerProductsData />} />
        <Route path='/product-meta-tags/:mediaId' element={<MetaRetailerProductsData />} />
        <Route path='/mediaId/:mediaId' element={<MediaIdDisplay />} />
        <Route path='/retailer-trends' element={<ProductsDesignTrends />} />
        <Route path='/designer-trends' element={<DesignTrendsFarFetch />} />
        <Route path='/color-and-pattern-trends' element={<SocialMetaTrends />} />
        <Route path='/designer-features' element={<DesignerFeatureTrends />} />
        <Route path='login' element={<Login />} />
        <Route path='query' element={<NewQuery />} />
        <Route path='s/:searchHash' element={<ImgUploded />} />
        <Route path='raw-images' element={<RaWImage />} />
        <Route path='trend-graph' element={<TrendGraph />} />
        <Route path='chatter-graph' element={<CommentsGraph />} />
        <Route path='saved' element={<WishList />} />
        <Route path='moodboard/:trendId' element={<Moodboard />} />
        <Route path='deleted-trend' element={<DeletedTrends />} />
        <Route path='curator/used-images' element={<ImageTagsTable />} />
        <Route path='curator/retailer-tags-files' element={<RetailerTags />} />
        <Route path='curator' element={<Curator />} />
        <Route path='curator-daily' element={<CuratorData />} />
        <Route path='/products-search' element={<ProductsSearch />} />
        <Route path='/admin/passcodes' element={<AdminPasscodes />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
